import { ConfirmPopup } from '@app/models/Popup';
import React, { useEffect, useRef } from 'react';
import { styled } from '@styled/jsx';

const Background = styled('div', {
  base: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    zIndex: '9999',
    backgroundColor: 'darkblack/30',
  },
});

const ConfirmWrapper = styled('div', {
  base: {
    width: '300px',
    boxSizing: 'border-box',
    padding: '0 git 12px',
    maxHeight: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 2px 4px 0 darkblack/12',
  },
});

const Text = styled('p', {
  base: {
    textAlign: 'center',
    padding: '30px 10px 20px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
    borderBottom: '1px solid {colors.gray.E6}',
    color: 'black',
  },
});

const Button = styled('button', {
  base: {
    width: '100%',
    height: '50px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    color: 'gray.66',
    _focus: {
      color: 'orange',
    },
  },
});

const ButtonList = styled('div', {
  base: {
    display: 'flex',
    position: 'relative',
    _after: {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '50%',
      width: '1px',
      height: '100%',
      backgroundColor: 'gray.E6',
    },
  },
});

type ConfirmProps = ConfirmPopup;

const Confirm: React.FC<ConfirmProps> = props => {
  const {
    message,
    options,
    confirmLabel = '확인',
    cancelLabel = '취소',
    onConfirm,
    onCancel,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    confirmButtonRef?.current?.focus();
    if (options?.easyCancel) return registerEasyCancelHandler();
    return;
    function registerEasyCancelHandler() {
      document.addEventListener('click', outsideClickHandler);
      document.addEventListener('keydown', escapeKeydownHandler);
      return () => {
        document.removeEventListener('click', outsideClickHandler);
        document.removeEventListener('keydown', escapeKeydownHandler);
      };
      function escapeKeydownHandler(e: KeyboardEvent) {
        if (e.key === 'Escape') onCancel?.();
      }
      function outsideClickHandler(e: MouseEvent) {
        // don't want to trigger onCancel when containerRef is missing
        if (
          !containerRef.current ||
          containerRef.current.contains(e.target as Node)
        )
          return;
        onCancel?.();
      }
    }
  }, [options, onCancel]);

  return (
    <Background>
      <ConfirmWrapper ref={containerRef}>
        <Text>{message}</Text>
        <ButtonList>
          <Button type="button" onClick={onConfirm} ref={confirmButtonRef}>
            {confirmLabel}
          </Button>
          <Button type="button" onClick={onCancel}>
            {cancelLabel}
          </Button>
        </ButtonList>
      </ConfirmWrapper>
    </Background>
  );
};

export default Confirm;
