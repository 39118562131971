import { StorageData, StorageKey, StorageValue } from '@app/models/storage';

const validateExpiredAt = (expiredAt: Date): boolean => {
  const now = new Date();

  return new Date(expiredAt) < now;
};

class StorageManager {
  private static _instance: StorageManager;

  private storage: Storage;

  private constructor() {
    this.storage = window.localStorage;
  }

  static get instance(): StorageManager {
    if (!this._instance) {
      this._instance = new StorageManager();
    }

    return this._instance;
  }

  checkExpiredBy(key: StorageKey): boolean {
    const data: StorageData | null = this.getItem(key);

    if (!data || !data.expiredAt) return true;

    return validateExpiredAt(data.expiredAt);
  }

  getItem(key: StorageKey): StorageData | null {
    const item = this.storage.getItem(key);

    if (!item) return null;

    const savedData = JSON.parse(item) as StorageData | null;

    if (savedData?.expiredAt && validateExpiredAt(savedData.expiredAt)) {
      this.removeItem(key);

      return null;
    }

    return savedData;
  }

  setItem(key: StorageKey, value: StorageValue, expiredAt?: Date): void {
    const savingData: StorageData = { value, expiredAt };

    if (expiredAt) {
      savingData.expiredAt = expiredAt;
    }

    this.storage.setItem(key, JSON.stringify(savingData));
  }

  removeItem(key: StorageKey): void {
    this.storage.removeItem(key);
  }
}

export default StorageManager.instance;
