import { AxiosPromise, AxiosResponse } from 'axios';
import {
  QueryClient,
  QueryKey,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import {
  AxiosInfiniteQueryOptionType,
  AxiosQueryOptionType,
} from '@app/models/Query';
import PaginationData from '@app/models/PaginationData';
import ServerResponse from '@app/models/response/ServerResponse';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const setQueryData = <T>(key: QueryKey, data: T): void => {
  const queryData = queryClient.getQueryData<AxiosResponse<T>>(key);

  if (queryData) {
    queryClient.setQueryData<AxiosResponse<T>>(key, {
      ...queryData,
      data,
    });
  }
};

export const useAxiosQuery = <T, U = T>(
  queryKey: QueryKey,
  queryFunction: () => AxiosPromise<T>,
  queryOptions?: AxiosQueryOptionType<T, U>
) => useQuery(queryKey, queryFunction, queryOptions);

export const useInfiniteAxiosQuery = <T, U = T>(
  queryKey: QueryKey,
  queryFunction: (param: { pageParam?: PaginationData }) => AxiosPromise<T>,
  queryOptions?: AxiosInfiniteQueryOptionType<T, U>
) => useInfiniteQuery(queryKey, queryFunction, queryOptions);

export function selectAxiosResponseData<T>(
  response: AxiosResponse<ServerResponse<T>>
) {
  return response.data.data;
}

export default queryClient;
