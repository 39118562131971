import { common } from '@klue/interface';
import { AxiosError } from 'axios';
import ServerErrorResponse from '@app/models/ServerErrorResponse';
import Popup from '@app/utils/popupUtils';
import { ERROR_MSG_BASE } from '../constants';

export const getApiErrorMessage = (
  error: AxiosError<ServerErrorResponse>
): string | undefined => {
  return error.response?.data?.msg;
};

export const alertApiError: (
  error: AxiosError<ServerErrorResponse>
) => void = error => {
  if (!error.response || !error.response.data) {
    Popup.alert({ message: ERROR_MSG_BASE });
    return;
  }
  const {
    response: {
      data: { errorCode, msg },
      status,
    },
  } = error;

  // expired refreshToken error
  // See also: https://github.com/klue-kr/klue-backend/blob/beed59/src/lib/errorHandler.ts#L81
  if (
    errorCode ===
    common.errors.KLUE_ERROR_VALUES.AUTH_EXPIRED_REFRESH_TOKEN.errorCode
  )
    return;

  const errorMessage =
    status >= 500
      ? 'KLUE 서버에 오류가 발생하였습니다. 잠시 후 다시 시도해주시고 지속적으로 발생 시, https://klue.kr/support에 접속해 문의 부탁드립니다.'
      : msg;
  Popup.alert({ message: errorMessage || ERROR_MSG_BASE });
};

export const textPad: (
  text: string,
  padText: string,
  length: number
) => string = (text, padText, length) => {
  return text.length >= length
    ? text
    : new Array(length - text.length + 1).join(padText) + text;
};

export const updateKey = <S, K extends keyof S>(
  state: S,
  key: K,
  value: S[K]
): S => {
  return {
    ...state,
    [key]: value,
  };
};

export const updateKeys = <S, K extends keyof S>(
  state: S,
  payload: { [key: string]: S[K] }
): S => {
  return {
    ...state,
    ...payload,
  };
};

export const includes = <T>(list: T[], value: unknown): value is T => {
  return list.some(item => item === value);
};

export const getQueryParams = (params: Record<string, string>) => {
  const search = Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return search ? `?${search}` : '';
};
