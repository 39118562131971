export enum PopupType {
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM',
  INPUT = 'INPUT',
}

export type AlertPopup = AlertPopupConfig & AlertPopupAction;
export interface AlertPopupConfig extends DefaultPopupConfig {
  closeLabel?: string;
}
interface AlertPopupAction {
  onClose?: () => void | Promise<void>;
}

export type ConfirmPopup = ConfirmPopupConfig & ConfirmPopupAction;
export interface ConfirmPopupConfig extends DefaultPopupConfig {
  confirmLabel?: string;
  cancelLabel?: string;
}
interface ConfirmPopupAction {
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}
export type InputPopup = InputPopupConfig & InputPopupAction;
export interface InputPopupConfig extends DefaultPopupConfig {
  confirmLabel?: string;
  cancelLabel?: string;
  placeholder?: string;
}
interface InputPopupAction {
  onConfirm?: (input: string) => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

export type PopupNode = CustomPopupNode;
type CustomPopupNode = PopupAlertNode | PopupConfirmNode | PopupInputNode;
type PopupAlertNode = { type: PopupType.ALERT; popup?: AlertPopup };
type PopupConfirmNode = { type: PopupType.CONFIRM; popup?: ConfirmPopup };
type PopupInputNode = { type: PopupType.INPUT; popup?: InputPopup };
export type DefaultPopupConfig = {
  message: string;
  options?: PopupOptions;
};
interface PopupOptions {
  /**
   * escape를 입력하거나 Popup 바깥을 클릭하더라도 종료될 수 있도록 합니다.
   * 등록한 onCancel, onClose 동작이 수행됩니다.
   */
  easyCancel?: boolean;
}
