import { common } from '@klue/interface';
import { useMutation, UseQueryResult } from '@tanstack/react-query';
import AuthApi from '@app/apis/AuthApi';
import { AxiosError } from 'axios';
import {
  AxiosMutationOptionType,
  AxiosQueryOptionType,
} from '@app/models/Query';
import ServerErrorResponse from '@app/models/ServerErrorResponse';
import StorageManager from '@app/utils/StorageManager';
import {
  ConfirmCodeRequest,
  ConfirmEmailRequest,
  ValidateSchoolEmailRequest,
} from '@app/models/request/Auth';
import { FetchAuthInfoResponse } from '@app/models/response/Auth';
import ServerResponse from '@app/models/response/ServerResponse';
import School from '@app/models/School';
import { useAxiosQuery } from '@app/utils/queryClient';
import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../constants';

interface ProcessLoginInfo {
  username: string;
  password: string;
  remember?: boolean;
}

interface ProcessRegisterInfoV2 {
  username: string;
  password: string;
  nickname: string;
  schoolId: string;
  studentNumber: string;
  personality: string;
  emailToken: string;
}

interface ProcessRegisterInfo {
  username: string;
  password: string;
  nickname: string;
  school_id: string;
  school_email: string;
  student_number: string;
  personality: string;
}

interface ProcessResetPasswordInfo {
  token: string;
  password: string;
}

interface ProcessMigrationInfo {
  username: string;
  password: string;
  nickname: string;
  personality: string;
  studentId: string;
}

interface ProcessFindUsernameInfo {
  schoolEmail: string;
  schoolId: string;
}

const authApi = new AuthApi();

export const useAuthInfoQuery = (
  options?: Exclude<
    AxiosQueryOptionType<
      ServerResponse<FetchAuthInfoResponse>,
      FetchAuthInfoResponse
    >,
    'enabled'
  >
): UseQueryResult<FetchAuthInfoResponse> => {
  return useAxiosQuery(['/auth/info'], () => authApi.fetchAuthInfo(), {
    select: response => response.data.data,
    ...options,
    enabled: false, // App에서 refetch로만 호출
  });
};

export const useSchoolsQuery = (
  options?: AxiosQueryOptionType<ServerResponse<School[]>, School[]>
): UseQueryResult<School[]> => {
  return useAxiosQuery(['/auth/schools'], () => authApi.fetchSchoolList(), {
    select: response => response.data.data,
    ...options,
  });
};

export const useLoginMutation = (
  options?: AxiosMutationOptionType<ProcessLoginInfo> & {
    onMigrateError?: (error: Error) => void;
  }
) => {
  // 로그인 성공시 로컬스토리지에 토큰값 저장
  const handleSuccess = (
    response: Awaited<ReturnType<typeof authApi.loginKlue>>
  ) => {
    const {
      headers: { authorization },
      data: {
        data: { refreshToken },
      },
    } = response;
    StorageManager.setItem(AUTH_TOKEN_NAME, authorization);
    StorageManager.setItem(REFRESH_TOKEN_NAME, refreshToken);
  };

  // 로그인 실패시 마이그레이션 에러코드 예외처리
  const handleError = (error: AxiosError<ServerErrorResponse>) => {
    if (
      error?.response?.data.errorCode ===
      common.errors.KLUE_ERROR_VALUES.AUTH_OLD_USER.errorCode
    ) {
      options?.onMigrateError?.(error);
    }
  };

  return useMutation(
    async ({ username, password, remember = false }: ProcessLoginInfo) => {
      const response = await authApi.loginKlue({
        username,
        password,
        remember,
      });
      return response;
    },
    {
      ...options,
      onSuccess: (response, ...rest) => {
        handleSuccess(response);
        options?.onSuccess?.(response, ...rest);
      },
      onError: (error, ...rest) => {
        handleError(error);
        options?.onError?.(error, ...rest);
      },
    }
  );
};

export const useLogoutMutation = (options?: AxiosMutationOptionType<void>) => {
  const handleSuccess = () => {
    StorageManager.removeItem(AUTH_TOKEN_NAME);
    StorageManager.removeItem(REFRESH_TOKEN_NAME);
  };

  return useMutation(
    async () => {
      const response = await authApi.logoutKlue();
      return response;
    },
    {
      ...options,
      onSuccess: (response, ...rest) => {
        handleSuccess();
        options?.onSuccess?.(response, ...rest);
      },
    }
  );
};

export const useRegisterMutation = (
  options?: AxiosMutationOptionType<ProcessRegisterInfo>
) => {
  return useMutation(async (data: ProcessRegisterInfo) => {
    const response = await authApi.registerKlue(data); // TODO
    return response;
  }, options);
};

export const useRegisterMutationV2 = (
  options?: AxiosMutationOptionType<ProcessRegisterInfoV2>
) => {
  return useMutation(async (data: ProcessRegisterInfoV2) => {
    const response = await authApi.registerKlueV2(data); // TODO
    return response;
  }, options);
};

export const useResetPasswordMutation = (
  options?: AxiosMutationOptionType<ProcessResetPasswordInfo>
) => {
  return useMutation(async (data: ProcessResetPasswordInfo) => {
    const { token, password } = data;
    const response = await authApi.resetPwd(token, password);
    return response;
  }, options);
};

export const useMigrationMutation = (
  options?: AxiosMutationOptionType<ProcessMigrationInfo> & {
    onBusinessError?: () => void;
  }
) => {
  // 마이그레이션 실패시 특정 비즈니스 에러코드 예외처리
  const handleError = (error: AxiosError<ServerErrorResponse>) => {
    if (
      error?.response?.data.errorCode ===
      common.errors.KLUE_ERROR_VALUES.AUTH_ALREADY_MIGRATION.errorCode
    ) {
      options?.onBusinessError?.();
    }
  };

  return useMutation(
    async (data: ProcessMigrationInfo) => {
      const { username, password, nickname, personality, studentId } = data;
      const response = await authApi.migrateKlue(
        username,
        password,
        nickname,
        personality,
        studentId
      );
      return response;
    },
    {
      ...options,
      onError: (error, ...rest) => {
        handleError(error);
        options?.onError?.(error, ...rest);
      },
    }
  );
};

export const useFindUsernameMutation = (
  options?: AxiosMutationOptionType<
    ProcessFindUsernameInfo,
    ServerResponse<string>
  >
) => {
  return useMutation(async (data: ProcessFindUsernameInfo) => {
    const { schoolId, schoolEmail } = data;
    const response = await authApi.findUsername(schoolId, schoolEmail);
    return response;
  }, options);
};

export const useFindPasswordMutation = (
  options?: AxiosMutationOptionType<string> & { onFindSuccess: () => void }
) => {
  const handleSuccess = (
    response: Awaited<ReturnType<typeof authApi.findPwd>>
  ) => {
    const isSuccessFindPassword = response.data.data === 'OK';
    isSuccessFindPassword && options?.onFindSuccess();
  };

  return useMutation(
    async (username: string) => {
      const response = await authApi.findPwd(username);
      return response;
    },
    {
      ...options,
      onSuccess: (response, ...rest) => {
        handleSuccess(response);
        options?.onSuccess?.(response, ...rest);
      },
    }
  );
};

export const useValidateNicknameMutation = (
  options?: AxiosMutationOptionType<string> & {
    onValidate: (result: boolean) => void;
  }
) => {
  const handleSettled = (
    response?: Awaited<ReturnType<typeof authApi.validateNickname>>
  ) => {
    const isValidationSuccess = response?.data?.data ?? false;
    options?.onValidate(isValidationSuccess);
  };

  return useMutation(
    async (nickname: string) => {
      const response = await authApi.validateNickname(nickname);
      return response;
    },
    {
      ...options,
      onSettled: (response, ...rest) => {
        handleSettled(response);
        options?.onSettled?.(response, ...rest);
      },
    }
  );
};

export const useValidateUsernameMutation = (
  options?: AxiosMutationOptionType<string> & {
    onValidate: (result: boolean) => void;
  }
) => {
  const handleSettled = (
    response?: Awaited<ReturnType<typeof authApi.validateUsername>>
  ) => {
    const isValidationSuccess = response?.data?.data ?? false;
    options?.onValidate(isValidationSuccess);
  };

  return useMutation(
    async (username: string) => {
      const response = await authApi.validateUsername(username);
      return response;
    },
    {
      ...options,
      onSettled: (response, ...rest) => {
        handleSettled(response);
        options?.onSettled?.(response, ...rest);
      },
    }
  );
};

export const useValidateEmailMutation = (
  options?: AxiosMutationOptionType<ValidateSchoolEmailRequest> & {
    onValidate: (result: boolean) => void;
  }
) => {
  const handleSettled = (
    response?: Awaited<ReturnType<typeof authApi.validateSchoolEmail>>
  ) => {
    const isValidationSuccess = response?.data?.data ?? false;
    options?.onValidate(isValidationSuccess);
  };

  return useMutation(
    async (data: ValidateSchoolEmailRequest) => {
      const response = await authApi.validateSchoolEmail(data);
      return response;
    },
    {
      ...options,
      onSettled: (response, ...rest) => {
        handleSettled(response);
        options?.onSettled?.(response, ...rest);
      },
    }
  );
};

export const useConfirmEmailMutation = (
  options?: AxiosMutationOptionType<ConfirmEmailRequest>
) => {
  return useMutation(
    async (data: ConfirmEmailRequest) => {
      const response = await authApi.sendConfirmEmail(data);
      return response;
    },
    { ...options }
  );
};

export const useConfirmCodeMutation = (
  options?: AxiosMutationOptionType<ConfirmCodeRequest>
) => {
  return useMutation(
    async (data: ConfirmCodeRequest) => {
      const response = await authApi.sendConfirmCode(data);
      return response;
    },
    { ...options }
  );
};
