import axios, { AxiosInstance } from 'axios';
import { errorInterceptor } from './AxiosInterceptor';

let axiosInstance: AxiosInstance | null = null;

const createAxiosInstance = () => {
  const newAxiosInstance = axios.create();
  newAxiosInstance.interceptors.response.use(
    config => config,
    errorInterceptor
  );
  return newAxiosInstance;
};

const getAxiosInstance = () => {
  if (!axiosInstance) {
    axiosInstance = createAxiosInstance();
  }
  return axiosInstance;
};

export default getAxiosInstance;
