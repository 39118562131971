import { QueryClientProvider } from '@tanstack/react-query';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import queryClient from '@app/utils/queryClient';
import App from './App';

import './index.css';

import * as Sentry from '@sentry/react';
import { Provider } from 'jotai';
import { store } from './atoms/store';

Sentry.init({
  dsn: 'https://cac6eae2c875d5856472e252fe25a764@o4505120176603136.ingest.us.sentry.io/4507883765628928',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);
