import Config from '@app/models/Config';

interface TestUser {
  username: string;
  password: string;
  userId: string;
  level: string;
}
export const TEST_USERS: TestUser[] =
  import.meta.env.MODE === 'development'
    ? [
        {
          username: 'TestUser1',
          password: '@d3vZfGHH3ThZ!@',
          userId: '67849',
          level: '10 (관리자)',
        },

        {
          username: 'TestUser2',
          password: '@d3vZfGHH3ThZ!@',
          userId: '67850',
          level: '3 (인증완료된 사용자)',
        },

        {
          username: 'TestUser3',
          password: '@d3vZfGHH3ThZ!@',
          userId: '67851',
          level: '3 (인증완료된 사용자)',
        },
      ]
    : [];

export default {
  apiHost: import.meta.env.VITE_API_HOST,
  apiV2Host: import.meta.env.VITE_API_V2_HOST,
  cdnHost: import.meta.env.VITE_CDN_HOST,
} satisfies Config;
