import { AlertPopup } from '@app/models/Popup';
import React, { useEffect, useRef } from 'react';
import { styled } from '@styled/jsx';

const Background = styled('div', {
  base: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    zIndex: '9999',
    backgroundColor: 'darkblack/30',
  },
});

const AlertWrapper = styled('div', {
  base: {
    width: '300px',
    boxSizing: 'border-box',
    padding: '0 12px',
    maxHeight: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 2px 4px 0 darkblack/12',
  },
});

const Text = styled('p', {
  base: {
    textAlign: 'center',
    padding: '30px 10px 20px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
    borderBottom: '1px solid {colors.gray.E6}',
    color: 'black',
  },
});

const Button = styled('button', {
  base: {
    width: '100%',
    height: '50px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    color: 'gray.66',
    _hover: {
      color: 'orange',
    },
    _active: {
      color: 'orange',
    },
  },
});

const Alert: React.FC<AlertPopup> = props => {
  const { options, message, closeLabel = '확인', onClose } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    closeButtonRef?.current?.focus();
    if (options?.easyCancel) return registerEasyCancelHandler();
    return;
    function registerEasyCancelHandler() {
      document.addEventListener('click', outsideClickHandler);
      document.addEventListener('keydown', escapeKeydownHandler);
      return () => {
        document.removeEventListener('click', outsideClickHandler);
        document.removeEventListener('keydown', escapeKeydownHandler);
      };
      function escapeKeydownHandler(e: KeyboardEvent) {
        if (e.key === 'Escape') onClose?.();
      }
      function outsideClickHandler(e: MouseEvent) {
        // don't want to trigger onCancel when containerRef is missing
        if (
          !containerRef.current ||
          containerRef.current.contains(e.target as Node)
        )
          return;
        onClose?.();
      }
    }
  }, [onClose, options]);

  return (
    <Background>
      <AlertWrapper ref={containerRef}>
        <Text>{message}</Text>
        <Button type="button" onClick={onClose} ref={closeButtonRef}>
          {closeLabel}
        </Button>
      </AlertWrapper>
    </Background>
  );
};

export default Alert;
