import { atom, useAtomValue, useSetAtom } from 'jotai';
import { PopupNode } from '@app/models/Popup';
import { atomEffect } from 'jotai-effect';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const popupAtom = atom<PopupNode[]>([]);
const peekAtom = atom<PopupNode | undefined>(get => {
  const popup = get(popupAtom);
  return popup[popup.length - 1];
});
export const pushAtom = atom<null, [PopupNode], void>(
  null,
  (get, set, node) => {
    set(popupAtom, [...get(popupAtom), node]);
  }
);
export const popAtom = atom(null, (get, set) => {
  set(popupAtom, [...get(popupAtom).slice(0, -1)]);
});

export function usePopup() {
  return [useAtomValue(peekAtom), useSetAtom(popAtom)] as const;
}

const scrollLockEffectAtom = atomEffect(get => {
  if (get(peekAtom)) disableBodyScroll(document.body);
  else enableBodyScroll(document.body);
});
export function usePopupScrollLockEffect() {
  return void useAtomValue(scrollLockEffectAtom);
}
