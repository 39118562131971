export const ERROR_MSG_BASE = '에러는 너굴맨이 처리했다구!';

export const AUTH_TOKEN_NAME = 'USER-KLUE';

export const REFRESH_TOKEN_NAME = 'REFRESH-TOKEN';

export const SEARCH_OFFSET = 10;

export const NICKNAME_ITEM_ID = 4;

export const INQUIRY_TABLE_DEFAULT_OFFSET = 5;

export const NOTICE_TABLE_DEFAULT_OFFSET = 10;

export const REGISTER_EMAIL_TOKEN = 'REGISTER-EMAIL-TOKEN';

export const REGISTER_MY_SCHOOL = 'REGISTER-MY-SCHOOL';

export const REGISTER_USER_CREDENTIAL = 'REGISTER-USER-CREDENTIAL';

export const AUTH_EXPIRED_JWT = 10905;
