import { AxiosPromise, Method as RequestMethod } from 'axios';
import StorageManager from '@app/utils/StorageManager';
import getAxiosInstance from './AxiosInstance';
import { AUTH_TOKEN_NAME } from '../../constants';
import queryClient from '@app/utils/queryClient';
import { FetchAuthInfoResponse } from '@app/models/response/Auth';
import ServerResponse from '@app/models/response/ServerResponse';

const defaultConfig = {
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

class ApiBuilder {
  private _method!: RequestMethod;

  private _data!: unknown;

  private _url!: string;

  private _headers: Record<string, unknown>;

  private _timeout: number;

  private _params!: unknown;

  public constructor() {
    const authInfo = queryClient.getQueryData<
      Awaited<AxiosPromise<ServerResponse<FetchAuthInfoResponse>>> // what?
    >(['/auth/info']);
    const userId = authInfo?.data.data.user_id;
    this._timeout = defaultConfig.timeout;
    this._headers = {
      ...defaultConfig.headers,
      Authorization: StorageManager.getItem(AUTH_TOKEN_NAME)?.value,
    };
    if (userId) {
      this._headers['User-Id'] = userId;
    }
  }

  public static create(): ApiBuilder {
    return new ApiBuilder();
  }

  public get(): this {
    this._method = 'GET';
    return this;
  }

  public post(): this {
    this._method = 'POST';
    return this;
  }

  public delete(): this {
    this._method = 'DELETE';
    return this;
  }

  public put(): this {
    this._method = 'PUT';
    return this;
  }

  public url(url: string): this {
    this._url = url;
    return this;
  }

  public timeout(timeout: number): this {
    this._timeout = timeout;
    return this;
  }

  public data(data: unknown): this {
    this._data = data;
    return this;
  }

  public headers(headers: Record<string, unknown>): this {
    this._headers = {
      ...this._headers,
      ...headers,
    };
    return this;
  }

  public params(params: unknown): this {
    this._params = params;
    return this;
  }

  public build(): AxiosPromise {
    const axiosInstance = getAxiosInstance();
    return axiosInstance.request({
      method: this._method,
      headers: this._headers,
      url: this._url,
      data: this._data,
      timeout: this._timeout,
      params: this._params,
    });
  }
}

export default ApiBuilder;
