import React from 'react';
import ReactDOM from 'react-dom';
import Alert from '@app/components/popup/Alert';
import { PopupType } from '@app/models/Popup';
import Confirm from '@app/components/popup/Confirm';
import Input from '@app/components/popup/Input';
import { usePopup } from '@app/atoms/popup';

const POPUP_PORTAL_ELEMENT_ID = 'popup';

const PopupPortal: React.FC = () => {
  const [current, pop] = usePopup();
  const portalElement = document.getElementById(POPUP_PORTAL_ELEMENT_ID);

  const getPortalComponent = () => {
    if (!current?.popup) return null;
    switch (current.type) {
      case PopupType.ALERT:
        return (
          <Alert
            message={current.popup.message}
            options={current.popup.options}
            closeLabel={current.popup.closeLabel}
            onClose={() => {
              pop();
              current.popup?.onClose?.();
            }}
          />
        );
      case PopupType.CONFIRM:
        return (
          <Confirm
            message={current.popup.message}
            options={current.popup.options}
            confirmLabel={current.popup.confirmLabel}
            onConfirm={() => {
              pop();
              current.popup?.onConfirm?.();
            }}
            cancelLabel={current.popup.cancelLabel}
            onCancel={() => {
              pop();
              current.popup?.onCancel?.();
            }}
          />
        );
      case PopupType.INPUT:
        return (
          <Input
            message={current.popup.message}
            options={current.popup.options}
            placeholder={current.popup.placeholder}
            confirmLabel={current.popup.confirmLabel}
            onConfirm={input => {
              pop();
              current.popup?.onConfirm?.(input);
            }}
            cancelLabel={current.popup?.cancelLabel}
            onCancel={() => {
              pop();
              current.popup?.onCancel?.();
            }}
          />
        );
      default:
        return null;
    }
  };

  if (!portalElement) {
    const newPortalElement = document.createElement('div');
    newPortalElement.setAttribute('id', POPUP_PORTAL_ELEMENT_ID);

    document.body.appendChild(newPortalElement);

    return ReactDOM.createPortal(getPortalComponent(), newPortalElement);
  }

  return ReactDOM.createPortal(getPortalComponent(), portalElement);
};

export default PopupPortal;
